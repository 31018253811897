import React from 'react';

const HomeLoanBenifits = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-[1260px] mx-auto">
      <h2 className="text-4xl font-semibold mb-4">Features and Benefits of our Home Loan</h2>
      <ul className="list-disc pl-5 space-y-2 text-lg">
        <li><strong>Home Construction Loan:</strong> If you have a plot of land and lack financial support to construct a home on that piece of property, you may choose a house construction loan.</li>
        <li><strong>Home Extension Loan:</strong> Should you already own a home, and feel the need to extend the building, add an annexe, or build another floor, etc., Home Extension Loan is your go-to option that finances such needs.</li>
        <li><strong>Home Improvement Loan:</strong> All of us wish to improve and upgrade our homes for a better life. From your old-age furniture, fixtures to faulty lighting systems, Home Improvement Loan funds all your plans.</li>
        <li><strong>NRI Home Loan:</strong> If you are a Non-Resident Indian and wish to own residential property in India, NRI Home Loan is your answer. Though the formalities for application may be a little different.</li>
      </ul>
      
    </div>
  );
}

export default HomeLoanBenifits;
