import React from 'react';
import InsuranceCard from '../Insurance/Insurance';
import EMICalculator from '../EmiCalculator/EmiCalculator';
import Aboutrelon from '../../components/AboutReloan/Aboutreloan';
import TimelineSection from '../AboutReloan/Timeline';
import FAQ from '../Faqcomponent/FAQ';
import CrouselComponent from '../CarouselHero/CrouselHero';

const loanData = [
 
  {
    title: 'Personal Loan',
    description: 'Turn Your Aspirations Into Reality With Our Comprehensive Selection Of Personal Loans.',
    image: '/assest/Creditcard/personal-loan.png', 
    link: '/personal-loan' 
  },
  {
    title: 'Home Loan',
    description: 'Your Dream Home Awaits - Explore Our Range Of Home Loan Products.',
    image: '/assest/Creditcard/home-loan.png', 
    link: '/home-loan' 
  },
  {
    title: 'Business Loan',
    description: 'Get The Financial Boost You Need For Your Business With Our Flexible Business Loans.',
    image: '/assest/Creditcard/Business-loan.png', 
    link: '/business-loan' 
  },
  {
    title: 'Loan against Property',
    description: 'Utilize Your Property\'s Potential To Fulfill Your Aspirations With Our Tailored Loan Against Property Options.',
    image: '/assest/Creditcard/loan-against-property.png', 
    link: '/property-loan' 
  },

];

function CreditCard() {
  return (
    <>
      <CrouselComponent/>
      <div className='bg-[#FEE4E4]'>
      <div className="p-4 md:p-8 lg:p-12 max-w-[1260px] mx-auto">
        <h1 className="text-3xl md:text-5xl font-bold mb-8 text-gray-900 text-center">Loans</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {loanData.map((loan, index) => (
            <div 
              key={index} 
              className="bg-white bg-gradient-to-r from-red-100 to-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl relative"
            >
              <div className="p-6">
                <img 
                  src={loan.image} 
                  alt={loan.title} 
                  className="w-20 h-20 object-contain mb-4 mx-auto"
                />
                <h2 className="text-2xl font-semibold mb-2 text-gray-800 text-center">{loan.title}</h2>
                <p className="text-gray-600 text-center mb-4">{loan.description}</p>
                <a 
                  href={loan.link} 
                  className="block bg-red-500 text-white text-center py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200 mx-auto max-w-[200px]"
                  style={{ width: 'fit-content' }}
                >
                  Explore More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
      <InsuranceCard/>
      <EMICalculator/>
      <Aboutrelon/>
      <TimelineSection/>
      <FAQ/>
    </>
  );
}

export default CreditCard;
