import { useState } from "react";
import { Link } from "react-router-dom";
import bluebuks from '../creaditcardComponents/cardTypeImages/bluebucks.jpg'
import RequestaCallback from "../components/EnquiryForm/RequestaCallback";
export default function Navbar() {
  const [loansDropdown, setLoansDropdown] = useState(false);
  const [dsaDropdown, setDsaDropdown] = useState(false);
  const [insuranceDropdown, setInsuranceDropdown] = useState(false);
  const [creditCardsDropdown, setCreditCardsDropdown] = useState(false);  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleDropdown = (dropdown) => {
    if (dropdown === "loans") {
      setLoansDropdown(!loansDropdown);
      setDsaDropdown(false);
      setInsuranceDropdown(false);
      setCreditCardsDropdown(false); 
    } else if (dropdown === "dsa") {
      setDsaDropdown(!dsaDropdown);
      setLoansDropdown(false);
      setInsuranceDropdown(false);
      setCreditCardsDropdown(false); 
    } else if (dropdown === "insurance") {
      setInsuranceDropdown(!insuranceDropdown);
      setLoansDropdown(false);
      setDsaDropdown(false);
      setCreditCardsDropdown(false);  
    } else if (dropdown === "creditCards") {  
      setCreditCardsDropdown(!creditCardsDropdown);
      setLoansDropdown(false);
      setDsaDropdown(false);
      setInsuranceDropdown(false); 
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
const [showmodal,setShowModal] = useState(false)
  return (
    <nav className="bg-white shadow-md">
      <RequestaCallback setshowModal={setShowModal} showModal={showmodal}/>
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
         <Link to='/' style={{    maxWidth: "210px",
    height: "36px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center"}}>
         <img src= {bluebuks} alt="Logo" style={{width:"100%",objectFit:"contain"}}/>
         </Link>
          <div className="hidden lg:flex space-x-4">
            <Link to='/about-us'>
            <a href="#" className="text-lg font-semibold">
              About Us
            </a>
            </Link>
            
            <div className="relative">
              <button
                onClick={() => toggleDropdown("loans")}
                className="text-lg font-semibold flex items-center"
              >
                Loans <span className="ml-1">&#9662;</span>
              </button>
              {loansDropdown && (
                <div className="absolute left-0 w-48 mt-2 bg-white border rounded shadow-lg">
                  <Link  to='/personal-loan'>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Personal Loan
                  </a>
                  </Link>
                   <Link to='/home-loan'>
                   <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Home Loan
                  </a>
                   </Link>
                  <Link to='/business-loan'>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Business Loan
                  </a>
                  </Link>
                   <Link to='/business-againstProperty'>
                   <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Loan Against Property
                  </a>
                   </Link>
                   
                   <Link to='/car-loans'>
                   <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Car Loan
                  </a>
                   </Link>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                onClick={() => toggleDropdown("dsa")}
                className="text-lg font-semibold flex items-center"
              >
                DSA <span className="ml-1">&#9662;</span>
              </button>
              {dsaDropdown && (
                <div className="absolute left-0 w-48 mt-2 bg-white border rounded shadow-lg">

                  <Link to='/personal-loan-dsa'>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Personal Loan DSA
                  </a>
                  </Link>
                  
                  <Link to='/home-loan-dsa'>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Home Loan DSA
                  </a>
                  </Link>


                  <Link to='/bussiness-loan-dsa'>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Business Loan DSA
                  </a>
                  </Link>
                  
                  <Link to='/loanagainst-property-dsa'>
                     <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                       Loan Against Property DSA
                     </a>
                  </Link>
                  
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Education Loan DSA
                  </a>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Auto Loan DSA
                  </a>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Credit Card Loan DSA
                  </a>
                </div>
              )}
            </div>
            {/* <div className="relative">
                 <Link to='/credit-cards'>
               <button
                onClick={() => toggleDropdown("creditCards")} 
                className="text-lg font-semibold flex items-center"
              >
                Credit Cards 
              </button>
               </Link>
             
            </div> */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("insurance")}
                className="text-lg font-semibold flex items-center"
              >
                Insurance <span className="ml-1">&#9662;</span>
              </button>
              {insuranceDropdown && (
                <div className="absolute left-0 w-48 mt-2 bg-white border rounded shadow-lg">
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    All Insurance
                  </a>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Life Insurance
                  </a>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    Health Insurance
                  </a>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                    General Insurance
                  </a>
                </div>
              )}
            </div>
            <a href="#" className="text-lg font-semibold">
              Contact Us
            </a>
            <a href="#" className="text-lg font-semibold">
              Blogs
            </a>
            <a href="#" className="text-lg font-semibold">
              Become a Partner
            </a>
            <a href="#" className="text-lg font-semibold">
              <span className="bg-red-500 text-white px-2 py-1 rounded">NEW</span> Check Cibil Score
            </a>
          </div>
        </div>
        <div className="hidden lg:flex lg:items-center">
          <a href="#" className="text-lg text-white font-semibold bg-[#ef4444] px-4 py-2 rounded-md" onClick={()=>setShowModal(true)}>
            Enquiry
          </a>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-2xl">
            {isMenuOpen ? "✖" : "☰"}
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div className="lg:hidden flex flex-col space-y-2 p-4">
          <a href="#" className="text-lg font-semibold">
            About Us
          </a>
          <div className="relative">
            <button
              onClick={() => toggleDropdown("loans")}
              className="text-lg font-semibold flex items-center"
            >
              Loans <span className="ml-1">&#9662;</span>
            </button>
            {loansDropdown && (
              <div className="mt-2 bg-white border rounded shadow-lg">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Personal Loan
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Business Loan
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Loan Against Property
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Car Loan
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Education Loan
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Gold Loan
                </a>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => toggleDropdown("dsa")}
              className="text-lg font-semibold flex items-center"
            >
              DSA <span className="ml-1">&#9662;</span>
            </button>
            {dsaDropdown && (
              <div className="mt-2 bg-white border rounded shadow-lg">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Personal Loan DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Home Loan DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Business Loan DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Loan Against Property DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Education Loan DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Auto Loan DSA
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Credit Card Loan DSA
                </a>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => toggleDropdown("creditCards")}  
              className="text-lg font-semibold flex items-center"
            >
              Credit Cards <span className="ml-1">&#9662;</span>
            </button>
            {creditCardsDropdown && (
              <div className="mt-2 bg-white border rounded shadow-lg">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  All Credit Cards
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Travel Credit Cards
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Cashback Credit Cards
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Premium Credit Cards
                </a>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => toggleDropdown("insurance")}
              className="text-lg font-semibold flex items-center"
            >
              Insurance <span className="ml-1">&#9662;</span>
            </button>
            {insuranceDropdown && (
              <div className="mt-2 bg-white border rounded shadow-lg">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  All Insurance
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Life Insurance
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Health Insurance
                </a>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  General Insurance
                </a>
              </div>
            )}
          </div>
          <a href="#" className="text-lg font-semibold">
            Contact Us
          </a>
          <a href="#" className="text-lg font-semibold">
            Blogs
          </a>
          <a href="#" className="text-lg font-semibold">
            Become a Partner
          </a>
          <a href="#" className="text-lg font-semibold">
            <span className="bg-red-500 text-white px-2 py-1 rounded">NEW</span> Check Cibil Score
          </a>
          <a href="#" className="text-lg text-white font-semibold bg-[#ef4444] px-4 py-2 rounded-md" onClick={()=>setShowModal(true)}>
           Enquiry
          </a>
        </div>
      )}
    </nav>
  );
}
