import Modal from "react-bootstrap/Modal";
import React from "react";
import RegisterFrom from "./RegisterFrom";
import "./RequestCallback.css";
function RequestaCallback({ showModal, setshowModal }) {
  const btnStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#ffff",
    cursor: "pointer",
  };
  const modalBackdropStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.9)", // Adjust the alpha value to change the darkness
    zIndex:"11000"
  };
  return (
    <div className="ed-reuest-callback-container">
      <Modal
        style={modalBackdropStyle}
        show={showModal}
        className="ed-reuest-callback-modal"
        onHide={() => setshowModal(false)}
        centered
      >
        <Modal.Body style={{ position: "relative" }}>
          <span
            className="close-video-modal"
            style={btnStyle}
            onClick={() => setshowModal(false)}
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
          <RegisterFrom setshowModal={setshowModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RequestaCallback;
