import React from 'react';
import businesscar from './cardTypeImages/businesscards.png';
import cashback from './cardTypeImages/cashback.png';
import fuel from './cardTypeImages/fuel.png';
import lifestyle from './cardTypeImages/lifestyle.png';
import reward from './cardTypeImages/reward.png';
import securedCard from './cardTypeImages/securedcard.png';
import soppingCart from './cardTypeImages/shopping.png';
import travel from './cardTypeImages/travel.png'
import leftTriangle from './cardTypeImages/lefttriangle (1).png';

const CardTypeImage = [
    {
        id: 1,
        backGroundImage: leftTriangle,
        cardImage: businesscar,
        imageName: 'Business Credit Cards'
    },
    {
        id: 2,
        backGroundImage: leftTriangle,
        cardImage: fuel,
        imageName: 'Fuel'
    },
    {
        id: 3,
        backGroundImage: leftTriangle,
        cardImage: lifestyle,
        imageName: 'lifestyle'
    },
    {
        id: 4,
        backGroundImage: leftTriangle,
        cardImage: reward,
        imageName: 'Rewards'
    },
    {
        id: 5,
        backGroundImage: leftTriangle,
        cardImage: securedCard,
        imageName: 'Secured Credit Cards'
    },
    {
        id: 6,
        backGroundImage: leftTriangle,
        cardImage: soppingCart,
        imageName: 'Shopping'
    },
    {
        id: 7,
        backGroundImage: leftTriangle,
        cardImage: travel,
        imageName: 'Travel'
    },
    {
        id: 9,
        backGroundImage: leftTriangle,
        cardImage: cashback,
        imageName: 'Cashback'
    },

]
function CardType() {
    return (
        <div className='max-w-[1260px] mx-auto mt-4'>
            <h1 className='text-4xl mt-8 font-semibold '>Types of Cards</h1>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-4 p-4 mx-auto justify-items-center'>
                {CardTypeImage.map((items) => (
                    <li
                        key={items.id}
                        style={{
                            backgroundImage: `url(${items.backGroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: "200px",
                            width: "200px"
                        }}
                        className="relative flex items-center justify-center overflow-hidden"
                    >
                        <img
                            src={items.cardImage}
                            alt={items.imageName}
                            className="absolute w-[100px] h-auto object-contain top-[35px] left-0"
                        />
                        <li className='absolute bottom-[10px] left-[10px] list-none text-xl'>{items.imageName}</li>
                    </li>
                ))}
            </div>
        </div>

    );
}

export default CardType;