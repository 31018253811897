import { useState } from 'react';
import percentageTag from './cardTypeImages/percentageTag.png'
import recordFolders from './cardTypeImages/recordFolders.png'
import RewardsGift from './cardTypeImages/RewardsGift.png'
import cashAlternative from './cardTypeImages/cashAlternative.png'
function CreditCardFeatures() {
  const [features] = useState([
    {
      icon:  percentageTag, 
      title: 'Credit Limit',
    },
    {
      icon:   cashAlternative, 
      title: 'Cash Alternative',
    },
    {
      icon: recordFolders,  
      title: 'Keep Records of all Transactions',
    },
    {
      icon:    RewardsGift,  
      title: 'Cashback, Rewards and Other Offers',
    },
  ]);

  return (
    <div className="container  px-4 py-8 max-w-[1260px] mx-auto">
      <h1 className="text-4xl mt-8 font-semibold">Credit Card Features</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {features.map((feature, index) => (
          <div key={index} className="bg-white rounded-md shadow-md p-4 mt-16">
            <div className="flex mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                 
              />
            </div>
            <h3 className="text-xl mb-2">{feature.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CreditCardFeatures;
