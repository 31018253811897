import React from 'react';

const DocumentsRequired = () => {
  return (
    <div className="max-w-screen-xl mx-auto bg-white  rounded-lg p-6">
      <h2 className="text-4xl font-semibold mb-4">Documents Required to Apply for a Credit Card</h2>
      <p className="text-lg mb-6">
        The documents needed to apply for a credit card can differ based on the issuing institution. However, the following are generally the frequently requested documents you would likely need to provide during the credit card application process:
      </p>
      <ul className="list-decimal  ml-8 mb-6 text-lg flex flex-col gap-2">
        <li> <span className=' font-semibold text-lg'>Identity Proof</span>: Need documents like your Aadhaar Card, PAN card, Driver's License, Passport, or Voter's ID to verify your identity.</li>
        <li><span className=' font-semibold text-lg'>Adress Proof</span>: You'll need to submit documents such as an Electricity bill, Telephone bill, Aadhaar Card, or other valid proof of your residential address.</li>
        <li><span className=' font-semibold text-lg'>Annual ITR (Self-Employed)</span>: For those who are self-employed or have alternative income sources, providing your annual Income Tax Returns (ITR) might be necessary to demonstrate your financial status.</li>
        <li><span className=' font-semibold text-lg'>Latest Salary Slips</span>: If you are a salaried individual, submitting your recent salary slips can help demonstrate your income and repayment capacity.</li>
      </ul>
      
    </div>
  );
};

export default DocumentsRequired;
