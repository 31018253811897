import React from 'react';
import boy from './cardTypeImages/boy1.png';
import girl from './cardTypeImages/girl1.png';
import quotes from './cardTypeImages/quotes.png';

const CreditCardReviews = () => {
  const reviews = [
    {
      name: "Shreya Mehta",
      rating: 4,
      review: "Ruloans offered me a lifetime free credit card from AXIS bank and also guided me on using the credit card the right way. Now I use my credit card for every short and big transaction which in result benefits me with a huge amount of reward points.",
      images: girl,
      quote: quotes,
    },
    {
      name: "Chirag Joshi",
      rating: 4,
      review: "The employees at Ruloans helped me in getting a credit card despite having a low credit score and are now also helping me to increase my credit score which can help me in future for getting any loan. They justify their tagline MUCH MORE THAN MONEY.",
      images: boy,
      quote: quotes,
    }
  ];

  return (
    <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col bg-white">
      <h2 className="text-3xl font-bold mb-8 text-center">Credit Card Reviews</h2>
      <div className='grid lg:grid-cols-2 gap-8'>
        {reviews.map((review, index) => (
          <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
            <div className='flex items-start gap-4 mb-4'>
              <img src={review.quote} alt="Quote Icon" className="w-10 " />
              <p className='text-yellow-400 text-3xl ml-6'>
                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
              </p>
            </div>

           
            
            <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
  <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
</div>

            

            <div className='flex items-center gap-4 mt-4'>
              <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
              <p className='font-semibold text-xl flex items-center'>
                {review.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreditCardReviews;
