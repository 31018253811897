import { useState } from 'react';
  import rightPersons from '../../creaditcardComponents/cardTypeImages/rightPersons.png'
  import coinsBlog from '../../creaditcardComponents/cardTypeImages/coinsBlog.png'
  import goldUp from '../../creaditcardComponents/cardTypeImages/goldUp.png'
  import homeLoan from '../../creaditcardComponents/cardTypeImages/homeLoan.png'
function CarLoanFeature() {
  const [features] = useState([
    {
      icon:  rightPersons, 
      title: 'Flexible Loan Eligibility',
    },
    {
      icon:   coinsBlog, 
      title: 'Loans value from Rs.10 Lakh to Rs. 5 Crore',
    },
    {
      icon: goldUp,  
      title: '30 Years of Tenure',
    },
    {
        icon: homeLoan,  
        title: 'Loan for your business as well as personal needs',
      },
  ]);

  return (
    <div className="container  px-4 py-8 max-w-[1260px] mx-auto">
      <h1 className="text-4xl mt-8 font-semibold">Car Loan Features</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {features.map((feature, index) => (
          <div key={index} className="bg-white rounded-md shadow-md p-4 mt-16">
            <div className="flex mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                 
              />
            </div>
            <h3 className="text-xl mb-2">{feature.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarLoanFeature;
