import React from 'react';

const BusinessLoanInfo = () => {
  return (
    <>
       <div className="max-w-[1260px] mx-auto p-8 bg-gradient-to-r from-blue-100 to-indigo-200 rounded-lg shadow-lg mt-4">
      <h1 className="text-3xl font-extrabold text-red-400 mb-6 text-center">Business Loan Information</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <h2 className="text-2xl font-semibold text-red-400 mb-2">1. High Loan Eligibility</h2>
          <p className="text-gray-700 text-lg">Prepare a solid business plan, check your credit score, decide on the loan amount, research available options, and have your documents ready.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <h2 className="text-2xl font-semibold text-red-400 mb-2">2. Required Documents</h2>
          <ul className=" list-inside text-gray-700 space-y-2 text-lg">
            <li>Proof of address & photo identity proof of promoters</li>
            <li>Business proof, income proof, partnership deed</li>
            <li>Articles of association, PAN card, etc.</li>
          </ul>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <h2 className="text-2xl font-semibold text-red-400 mb-2">3. Loan Approval Criteria</h2>
          <p className="text-gray-700 text-lg">Applicants aged 21-65 years, with a business vintage of 1-2 years and profit-making for at least the last year, are eligible.</p>
        </div>
      </div>
    </div>
     

    <div className="max-w-[1260px] mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold text-gray-800 mb-8">
        Features and Benefits of our Business Loans
      </h2>
      <ul className="list-disc list-inside text-gray-600 flex flex-col gap-2 text-lg ml-4">
        <li>Term Loans</li>
        <li>Working Capital Loans</li>
        <li>Business Expansion Loans</li>
        <li>Equipment Financing Loans</li>
        <li>Invoice Financing & Bill Discounting</li>
        <li>Machinery Loans</li>
      </ul>
    </div>
    </>

  );
};

export default BusinessLoanInfo;
