import React from 'react';

function PersonalLoanEligibilityCriteria() {
  return (
    <div className="max-w-[1260px] mx-auto p-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Personal Loan Eligibility Criteria
      </h1>
      <p className="text-gray-600 mb-6">
        To qualify for a personal loan, you have to meet certain criteria. Below
        are the important factors that lenders take into consideration to decide
        your eligibility for a personal loan.
      </p>
      <ul className="list-disc ml-6 text-gray-700 text-lg flex flex-col gap-2">
        <li>
          Age should fall under the range of 21 years to 60 years
        </li>
        <li>
          Net monthly income should be 15,000/- for salaried and self-employed,
          yearly transactions should be a minimum of 20 lakhs
        </li>
        <li>Credit score must be above 650</li>
        <li>Debt-to-income ratio</li>
        <li>Employment stability</li>
        <li>Maintained a good credit score</li>
        <li>Clear repayment history</li>
        <li>Must be a Resident Citizen of India</li>
      </ul>
    </div>
  );
}

export default PersonalLoanEligibilityCriteria;
