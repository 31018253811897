import React, { useState } from "react";

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(7.5);
  const [loanTenure, setLoanTenure] = useState(20);

  const calculateEMI = (amount, rate, tenure) => {
    const monthlyInterestRate = rate / 12 / 100;
    const numberOfMonths = tenure * 12;

    const emi =
      (amount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfMonths)) /
      (Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1);

    const totalAmountPayable = emi * numberOfMonths;
    const interestPayable = totalAmountPayable - amount;

    return { emi, totalAmountPayable, interestPayable };
  };

  const { emi, totalAmountPayable, interestPayable } = calculateEMI(
    loanAmount,
    interestRate,
    loanTenure
  );

  return (
    <div className="bg-[#FEE7E7] p-[5%]">
        <div className="flex justify-center items-center  max-w-[1260px] mx-auto">
      <div className="bg-white p-8 rounded-lg w-full flex flex-col md:flex-row">
        <div className="w-full md:w-3/5 pr-0 md:pr-8 mb-8 md:mb-0">
          <h2 className="text-2xl font-bold mb-4">EMI Calculator</h2>
          <p className="text-gray-600 mb-8">
            Check your EMI based on the loan amount, tenure, and the interest rate offered.
          </p>

          {/* Loan Amount */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Loan Amount (₹)</label>
            <input
              type="range"
              min="0"
              max="50000000"
              step="100000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number(e.target.value))}
              className="w-full appearance-none bg-gray-200 rounded-full h-2"
              style={{
                background: `linear-gradient(to right, #f87171 ${(loanAmount / 50000000) * 100}%, #e5e7eb ${(loanAmount / 50000000) * 100}%)`,
              }}
            />
            <div className="flex justify-between mt-2 text-gray-600">
              <span>₹0</span>
              <span>₹{(loanAmount / 10000000).toFixed(1)} Cr</span>
            </div>
          </div>

          {/* Interest Rate */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Interest Rate (%)</label>
            <input
              type="range"
              min="4"
              max="24"
              step="0.01"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
              className="w-full appearance-none bg-gray-200 rounded-full h-2"
              style={{
                background: `linear-gradient(to right, #f87171 ${(interestRate - 4) / 20 * 100}%, #e5e7eb ${(interestRate - 4) / 20 * 100}%)`,
              }}
            />
            <div className="flex justify-between mt-2 text-gray-600">
              <span>{interestRate}%</span>
            </div>
          </div>

          {/* Loan Tenure */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Loan Tenure (Years)</label>
            <input
              type="range"
              min="1"
              max="30"
              step="1"
              value={loanTenure}
              onChange={(e) => setLoanTenure(Number(e.target.value))}
              className="w-full appearance-none bg-gray-200 rounded-full h-2"
              style={{
                background: `linear-gradient(to right, #f87171 ${(loanTenure - 1) / 29 * 100}%, #e5e7eb ${(loanTenure - 1) / 29 * 100}%)`,
              }}
            />
            <div className="flex justify-between mt-2 text-gray-600">
              <span>{loanTenure} Years</span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/5 bg-gray-50 p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold text-blue-600 mb-4">
            ₹{emi.toFixed(2).toLocaleString()}
          </h3>
          <p className="text-gray-500 text-sm">Monthly Loan EMI</p>

          <div className="flex justify-between items-center mt-6">
            <div className="flex items-center">
              <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
              <div className="text-gray-600">Principal Amount</div>
            </div>
            <div className="text-gray-800">₹{loanAmount.toLocaleString()}</div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <div className="w-3 h-3 bg-blue-500 rounded-full mr-2"></div>
              <div className="text-gray-600">Interest Payable</div>
            </div>
            <div className="text-gray-800">₹{interestPayable.toLocaleString()}</div>
          </div>

          <div className="mt-8 border-t pt-4">
            <div className="text-gray-600">Total Amount Payable</div>
            <div className="text-xl font-bold text-gray-800">
              ₹{totalAmountPayable.toLocaleString()}
            </div>
          </div>

          <button className="bg-red-500 text-white px-6 py-3 rounded-lg mt-6 w-full">
            Get Started
          </button>
        </div>
      </div>

      <style jsx>{`
        input[type="range"]::-webkit-slider-thumb {
          appearance: none;
          width: 20px;
          height: 20px;
          background-color: #f87171;
          border-radius: 50%;
          cursor: pointer;
        }

        input[type="range"]::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background-color: #f87171;
          border-radius: 50%;
          cursor: pointer;
        }

        input[type="range"]::-ms-thumb {
          width: 20px;
          height: 20px;
          background-color: #f87171;
          border-radius: 50%;
          cursor: pointer;
        }
      `}</style>
    </div>
    </div>
  );
};

export default EMICalculator;
