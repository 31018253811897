import React from 'react';

const HomeServices = () => {
  const cities = [
    {
      title: 'Ahmedabad',
      cities: [
        'Ahmedabad',
        'Hyderabad',
        'Lucknow',
        'Vapi',
        'Udvada',
        'Mumbai',
      ],
    },
    {
      title: 'Bangalore',
      cities: ['Bangalore', 'Kolkata', 'Pune', 'Valsad', 'Diu', 'Kevadiya'],
    },
    {
      title: 'Jodhpur',
      cities: ['Jodhpur', 'Nashik', 'Delhi', 'Silvassa', 'Umbergaon'],
    },
    {
      title: 'Gujarat',
      cities: ['Gujarat', 'Varanasi', 'Jaipur', 'Daman', 'Vadodara'],
    },
    {
      title: 'Nagpur',
      cities: ['Nagpur', 'Bhopal', 'Mangalore', 'Hazira', 'Saputara'],
    },
    {
      title: 'Surat',
      cities: ['Surat', 'Indore', 'Raipur', 'Bharuch', 'Vikramgad'],
    },
  ];

  return (
    <div className="max-w-[1260px] mx-auto px-4 shadow-lg py-16">
      <h2 className="text-3xl font-bold text-center mb-8">
        We Are Available In Cities
      </h2>
      <div className="flex flex-wrap justify-center gap-10">
        {cities.map((city) => (
          <div key={city.title} className="bg-white rounded-lg  p-6">
             
            <ul className="text-lg cursor-pointer">
              {city.cities.map((city) => (
                <li key={city} className="mb-2">
                  {city}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeServices;