import React from 'react';

const insuranceData = [
  {
    title: 'Life Insurance',
    description: "Protect Your Loved One's Future With Our Reliable Life Insurance Plans.",
    image: '/assest/Insurance/life-insurance.png', 
    link: '/life-insurance',
    price: 'Starting from ₹ 450/month*',
  },
  {
    title: 'Health Insurance',
    description: 'Ensuring Your Health Is In Good Hands - Explore Our Top-notch Insurance Solutions.',
    image: '/assest/Insurance/health-insurance.png', 
    link: '/health-insurance',
    price: 'Starting from ₹ 450/month*',
  },
  {
    title: 'General Insurance',
    description: 'Insurance Made Easy - Your Protection, Our Expertise.',
    image: '/assest/Insurance/general-insurance.png', 
    link: '/general-insurance',
    price: 'Starting from ₹ 450/month*',
  }
];

function InsuranceCard() {
  return (
     <div className='bg-white'>
        <div className="p-4 md:p-8 lg:p-12 max-w-[1260px] mx-auto">
      <h1 className="text-3xl md:text-5xl font-bold mb-8 text-gray-900">Property Dealer</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {insuranceData.map((insurance, index) => (
          <div 
            key={index} 
            className="bg-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl relative"
          >
            <div className="p-6 bg-gradient-to-r from-red-100 to-white">
              <img 
                src={insurance.image} 
                alt={insurance.title} 
                className="w-16 h-16 object-contain mb-4 mx-auto"
              />
              <h2 className="text-2xl font-semibold mb-2 text-gray-800 text-center">{insurance.title}</h2>
              <p className="text-gray-600 text-center mb-4">{insurance.description}</p>
              <p className="text-lg font-semibold text-gray-800 text-center mb-4">{insurance.price}</p>
              <a 
                href={insurance.link} 
                className="text-white bg-red-500 hover:bg-red-600 py-2 px-4 rounded-full block text-center mx-auto mt-4 max-w-[200px]"
              >
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
     </div>
  );
}

export default InsuranceCard;
