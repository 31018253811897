import React from 'react';

const CreditCardEligibility = () => {
  return (
    <div className="max-w-[1260px] mx-auto  shadow-md rounded-lg  p-4  bg-[#EEF4FF] border border-[#8A98BF]">
      <h2 className="text-3xl  font-semibold mb-2">Credit Card Eligibility and Documents</h2>
       <div className='flex justify-between mt-8 flex-wrap '>
       <p className="text-gray-700 mb-4 text-xl">
        Read on to know the criteria required to apply for our Credit Card.
      </p>
      <button className="bg-red-500 text-white py-4 px-16 rounded text-xl hover:bg-red-600 font-bold">
        Apply
      </button>
       </div>
    </div>
  );
};

export default CreditCardEligibility;
