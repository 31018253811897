import { useState } from 'react';

function DocumentsRequired() {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
      <div>
<div className="max-w-[1260px] mx-auto p-4 ">
      <h2 className="text-2xl font-bold mb-4">
        Documents Required to Apply for Personal Loan
      </h2>
      <div className="border rounded-md p-4">
        <ul className='flex flex-col gap-2 text-lg'>
          <li className="mb-2">
            <span className="font-bold">Identity Proof</span> - Passport, Voter's
            ID, Driving License, PAN Card, Aadhaar Card
          </li>
          <li className="mb-2">
            <span className="font-bold">Proof of Residence or Address Proof</span>
            - Passport, Voter's ID, Driving License, PAN Card, Aadhaar Card,
            Electricity Bill, Telephone Bill, Ration Card
          </li>
          <li className="mb-2">
            <span className="font-bold">Age Proof</span> - Passport, Voter's
            ID, Driving License, PAN Card, Aadhaar Card
          </li>
          <li className="mb-2">
            <span className="font-bold">Income Proof</span> - 1 year Bank
            statement, 3 months Salary Slips
          </li>
          <li className="mb-2">
            <span className="font-bold">Employment Proof</span> - Employment
            Certificate, Office address proof
          </li>
          <li className="mb-2">
            <span className="font-bold">GST or VAT Registration for
            Self-employed</span>
          </li>
          <li className="mb-2">
            <span className="font-bold">Photograph</span> - Passport-size
            photographs
          </li>
          <li className="mb-2">
            <span className="font-bold">Business Proof</span> - Business
            registration documents such as a partnership deed, Memorandum of
            Association (MOA), Articles of Association (AOA), etc.
          </li>
          <li className="mb-2">
            <span className="font-bold">Income Tax Returns</span> - Documents
            of the past 2-3 years to verify income and tax payment history
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-bold mt-8 mb-4">
        EMI Calculator for Personal Loan
      </h2>
      <div className="border rounded-md p-4 flex flex-col gap-2 text-lg">
        <p>
          An EMI calculator is a useful tool that can help you estimate the
          monthly installments you will have to pay towards your personal loan
          within a specific period. By using the Ruloans{' '}
          <a href="#" className="underline text-blue-500">
            Personal Loan EMI calculator
          </a>
          , you can calculate your EMI beforehand, which can help you plan
          your finances better. Additionally, you can check your eligibility
          and compare different loan options using Ruloans Personal Loan
          calculator.
        </p>
        <button
          className="bg-red-500 hover:bg-red-700 w-[200px] rounded-md text-white font-bold py-4 px-4 focus:outline-none focus:shadow-outline"
          onClick={toggleDetails}
        >
          {showDetails ? 'Hide Details' : 'Show More Details'}
        </button>
        {showDetails && (
          <div className="mt-4">
            
          </div>
        )}
      </div>
    </div>
      </div>
  );
}

export default DocumentsRequired;