import React from 'react'
 

const DetailsCreditCards = () => {
    return (

        <div className='max-w-[1260px] mx-auto mt-4'>
            <div>
                <h1 className='text-3xl text-black font-semibold'>What is a Credit Card?</h1>
                <p className='mt-4 font-sans text-[18px]'>A credit card is a financial instrument that has a pre-loaded balance that the cardholder can use to make transactions and pay for them later. The card issuer will let you pay off the balance fully, interest-free, for up to 50 days from the date of payment. To avoid any fines, the cardholder can pay the minimum amount due (which can be anywhere between 5% to 10% on the total amount owed). The balance, however, will be carried forward to the next month, and interest set by the credit card company will be levied.</p>
            </div>
            <div className='mt-4'>
                <div>
                    <div className='mt-4'>
                        <h1 className='text-3xl text-black font-semibold'>Different Types of Credit Cards in India</h1>
                        <div className='mt-2'>
                            <span className='inline-block text-black font-bold text-xl'>Shopping Credit Cards -</span>
                            <p className='inline text-[18px]'>Shopping credit cards are curated to reward cardholders for their shopping expenses. They offer ample rewards in the form of points or cashback, which can be redeemed for merchandise, travel, or other rewards.
                            </p>
                        </div>

                    </div>

                    <div className='mt-4'>
                        <span className='inline-block text-black font-bold text-xl'>Rewards Credit Cards - </span>
                        <p className='inline text-[18px]'> Rewards credit cards offer you some type of "reward"—typically cash back, points, or travel miles—for every dollar you spend, sometimes up to certain limits. Depending on the card, you can then use your rewards in a variety of ways.
                        </p>
                    </div>

                    <div className='mt-4'>
                        <span className='inline-block text-black font-bold text-xl'>Travel Credit Cards -</span>
                        <p className='inline text-[18px]'>Travel credit card is a type of credit card that offers exciting offers and deals related to travel. Most features and benefits offered by travel credit cards provide you savings on travel. From air ticket bookings to reward points accumulation, everything comes with a perk when done through travel credit card.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DetailsCreditCards