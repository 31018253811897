import React from 'react'
import { createBrowserRouter, createRoutesFromElements,Route, RouterProvider } from 'react-router-dom'
import LayOut from '../Layout/LayOut'
import CreditCard from '../components/Creditcard/Creditcard'
import CreditCardNavbar from '../creaditcardComponents/CreditCardNavbar'
 import HomeLoanNavbar from '../Loans/homeLoan/HomeLoanNavbar'
import PersonalLoanNavbar from '../Loans/personalLoan/PersonalLoanNavbar'
import Note from '../components/About/Note'
import PropertNavbar from '../Loans/loanagainstproperty/PropertNavbar'
import BussinessLoanNavbar from '../Loans/bussinessLoan/BussinessLoanNavbar'
import CarLoanNavbaar from '../Loans/carLoans/CarLoanNavbaar'
import PersonalLoanDsa from '../Loans/dsa/PersonalLoanDsa/PersonalLoanDsa'
import HomeLoanDsa from '../Loans/dsa/homeLoanDsa/HomeLoanDsa'
 import BussinLoanDsa from '../Loans/dsa/bussinessLoan/BussinessLoanDsa'
import LoanAgainstProperty from '../Loans/dsa/LoanAgainstProperty/LoanAgainstProperty'

const LoansRoute = () => {
    const route = createBrowserRouter(createRoutesFromElements(
        <Route path='/' element={<LayOut/>}>
           <Route path='/' element={<CreditCard/>}/>
           <Route path='/credit-cards' element={<CreditCardNavbar/>}/>
           <Route path='/about-us' element={<Note/>}/>
           <Route path='/home-loan' element={<HomeLoanNavbar/>}/>
           <Route path='/personal-loan' element={<PersonalLoanNavbar/>}/>
           <Route path='/business-loan' element={<BussinessLoanNavbar/>}/>
           <Route path='/business-againstProperty' element={<PropertNavbar/>}/>
           <Route path='/car-loans' element={<CarLoanNavbaar/>}/>
           <Route path='/personal-loan-dsa' element={<PersonalLoanDsa/>}/>
           <Route path='/home-loan-dsa' element={<HomeLoanDsa/>}/>
           <Route path='/bussiness-loan-dsa' element={<BussinLoanDsa/>}/>
           
           <Route path='/loanagainst-property-dsa' element={<LoanAgainstProperty/>}/>
        </Route>
    ))
  return (
   
        <RouterProvider router={route}/>
     
  )
}

export default LoansRoute