import React, { useState } from 'react';
import CarLoanFeature from './CarLoanFeature';
 import CarFeature from './CarFeature'
 import CarLoanEligibility from './CarLoanEligibility'
import CarLoanEligibleRead from './CarLoanEligibleRead';
import CarLoansCharges from './CarLoansCharges';
import CarLoanRewies from './CarLoanRewies';
import CarLoanFaq from './CarLoanFaq';
const CarLoanNavbaar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');
  const tabs = [
    ' OVERVIEW',
    "FEATURES",
    "ELIGIBILITY",
    "DOCUMENTS",
    "EMI CALCULATOR",
    "FEES AND CHARGES",
    "REVIEWS",
    "FAQ`s"
  ];

  return (
    <>
      <div className="App">
        <div className="flex space-x-4 border-b-2 font-semibold">
          {tabs.map(tab => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
       <CarLoanFeature/>
       <CarFeature/>
       <CarLoanEligibility/>
       <CarLoanEligibleRead/>
       <CarLoansCharges/>
       <CarLoanRewies/>
       <CarLoanFaq/>
    </>
  );
};

export default CarLoanNavbaar;
