import React, { useState, useEffect } from 'react';
import RegisterFrom from '../EnquiryForm/RegisterFrom';

const slides = [
  {
    id: 1,
    title: "Check Your Free CIBIL Score Now! ",
    description: "Get your instant CIBIL score and detailed credit report with just a few clicks!"
,
    image: "/assest/Hero/banner1.png",
    buttonText: "Know More"
  },
  {
    id: 2,
    title: "Unlock Your Financial Potential",

    description: "Get your instant CIBIL score and detailed credit report with just a few clicks!",

    image: "/assest/Hero/banner2.png",
    buttonText: "Know More"
  },
  {
    id: 3,
    title: "India’s First B2B Loan Distribution Partner App",
    description: "",
    image: "/assest/Hero/banner3.png",
    buttonText: "Know More"
  }
];

const CrouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Autoplay functionality
  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);  
    return () => clearInterval(timer);  
  }, []);

  return (
    <div className='relative overflow-hidden h-screen'>
      {/* Triangle Background */}
      <div className='absolute inset-0 -z-10'>
        <div className='absolute inset-0'>
          <div className='w-screen h-screen bg-red-500 clip-triangle'></div>
        </div>
      </div>

      <div className="relative max-w-[1260px] mx-auto">
        <div className="overflow-hidden pt-5 pb-5"style={{display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap"}}>
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`flex flex-col md:flex-row items-center justify-between p-6 transition-transform duration-500 ease-in-out ${index === currentSlide ? 'translate-x-0' : 'translate-x-full'} ${index < currentSlide ? '-translate-x-full' : ''}`}
              style={{ display: index === currentSlide ? 'flex' : 'none',flexBasis:"300px",flexGrow:1 }}
            >
              <div className="flex flex-col items-center md:items-start">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800 text-center">{slide.title}</h2>
                <p className="text-base md:text-lg text-gray-600 mb-6 text-center ">{slide.description}</p>
                <button className="bg-red-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg hover:bg-red-600 focus:outline-none">
                  {slide.buttonText}
                </button>
              </div>
            </div>
            ))}
              <div className="w-full md:w-1/2 lg:w-3/5 flex justify-center md:justify-end" style={{flexBasis:"300px",flexGrow:1}}>
              <RegisterFrom/>
              </div>
        </div>

        {/* Dots */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 mx-1 rounded-full cursor-pointer transition-colors duration-300 ${index === currentSlide ? 'bg-blue-600 transform scale-110' : 'bg-gray-400 hover:bg-blue-400'}`}
              onClick={() => setCurrentSlide(index)}
            ></div>
          ))}
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .clip-triangle {
          clip-path: polygon(-10% 100%, 110% 100%, 110% -10%);
        }
      `}</style>
    </div>
  );
};

export default CrouselComponent;
