import React, { useState } from 'react';
import PropertyFeature from './PropertyFeature'
import FeatureUser from './FeatureUser';
import PropertyEligibility from './PropertyEligibility';
 import LoanPropert from './LoanPropert';
import LoanPropertyCharges from './LoanPropertyCharges';
import LoanPropertyReview from './LoanPropertyReview';
 import LoanPropertfaq from './LoanPropertfaq'
import CarLoanEligibleRead from '../carLoans/CarLoanEligibleRead';

const PropertNavbar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  const tabs = [
   ' OVERVIEW',
   ' FEATURES',
   ' ELIGIBILITY',
   ' DOCUMENTS',
    'EMI CALCULATOR',
   ' FEES AND CHARGES',
   ' REVIEWS',
    'FAQ`s'
  ];

  return (
    <>
      <div className="App">
        <div className="flex space-x-4 border-b-2 font-semibold">
          {tabs.map(tab => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      
      <PropertyFeature/>
      <FeatureUser/>
      <PropertyEligibility/>
       <LoanPropert/>
       <LoanPropertyCharges/>
       <LoanPropertyReview/>
       <LoanPropertfaq/>
        
    </>
  );
};

export default PropertNavbar;
