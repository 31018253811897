import React, { useState } from 'react';
  import PersonalLoanFeature from './PersonalLoanFeature';
import PersonalLoanBenifit from './PersonalLoanBenifit';
import PersonalLoanEligibility from './PersonalLoanEligibility';
import PersonalLoanEligibilityCriteria from './PersonalLoanEligibilityCriteria'
import DocumentsRequired from './DocumentsRequired';
import Charges from './Charges';
import PersonalLoanReview from './PersonalLoanReview';
import PersonalLoanFaq from './PersonalLoanFaq';
const PersonalLoanNavba = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  const tabs = [
    'OVERVIEW', 'SALES', 'EXPENSES', 'ORDERS', 'P&L CALCULATOR', 'PEERS COMPARISON', 'ESTIMATES', 'P&L'
  ];

  return (
    <>
       <div className="App">
      <div className="flex space-x-4 border-b-2">
        {tabs.map(tab => (
          <button
            key={tab}
            className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
    <PersonalLoanFeature/>
    <PersonalLoanBenifit/>
    <PersonalLoanEligibility/>
    <PersonalLoanEligibilityCriteria/>
    <DocumentsRequired/>
    <Charges/>
    <PersonalLoanReview/>
    <PersonalLoanFaq/>
    </>
  );
};

export default PersonalLoanNavba;
