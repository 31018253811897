import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons from react-icons

const BussinesLoanfaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is a home loan?",
        
      answer: "A home loan is a loan used to buy a home. It is repaid over 15 to 30 years and is secured by the property being purchased. If the borrower fails to repay, the lender can take possession of the property."
    },
    {
      question: "How do I qualify for a home loan?",
      answer: "To qualify for a home loan, you typically need a good credit score (usually above 620), a steady income, and a low debt-to-income ratio. Lenders also consider your employment history. If you meet all of the lenders perimeters than you are eligible for a home loan."
    },
    {
      question: "Can I prepay my home loan?",
      answer: 
"Yes, you can usually prepay your home loan in two ways such as full prepayment and part prepayment. If you have the remaining principle amount availabe with then you can fully pay the amount and close the loan or the payment also can be made in parts and the principle will be reduced by the same amount.  ",
    },
    {
        question: "Is it possible to secure a 100% home loan from a bank?",
        answer: 
  "No, it is not possible to secure 100% loan from the bank.However banks provide upto 90-95% of the agreement value.",
      },

      {
        question: "What is the maximum home loan that I can get?",
        answer: 
  "The maximum home loan that you can get is not capped by any amount but it is given on the basis of the agreement value and also the borrorwers income eligibilty and credit history. generally banks provide 90-95% of the agreement value.",
      },
      {
        question: "What documentation is required for salaried professionals applying for a home loan?",
        answer: 
  "Salaried professionals applying for a home loan need to provide proof of identity, address, and income, along with employment proof, Professional certificate  income tax returns, property documents, bank statements,",
      },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-[1260px] mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">FAQs</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border rounded-lg overflow-hidden">
            <button
              className="w-full p-4 text-left font-semibold bg-gray-200 hover:bg-gray-300 focus:outline-none flex items-center justify-between"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
              {activeIndex === index ? (
                <FaChevronUp className="text-gray-600" />
              ) : (
                <FaChevronDown className="text-gray-600" />
              )}
            </button>
            {activeIndex === index && (
              <div className="p-4 bg-gray-100">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BussinesLoanfaq;
