import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';  

const DsaFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the minimum credit score required to qualify for a personal loan?",
        
      answer: "Depending on several factors, including the loan amount, loan term, income, and employment history, different lenders have different minimum credit score requirements to be approved for personal loans. Most lenders consider a credit score of 650 or above to be strong enough to qualify for a personal loan. To be eligible for a personal loan with low-interest rates and advantageous terms, some lenders may require a credit score of 700 or better."
    },
    {
      question: "What is the minimum salary required to be eligible for a personal loan?",
      answer: "The minimum salary required to be eligible for a personal loan can vary depending on the lender and their specific eligibility criteria. However, in India, most lenders have a minimum salary requirement of Rs. 15,000 per month to qualify for a personal loan. This means that if you earn less than Rs. 15,000 per month, you may not be eligible for a personal loan from these lenders."
    },
    {
      question: "Can pensioners with a pension account at one of the leading banks in India apply for a personal loan?",
      answer: 
"Personal loans for pensioners may have different eligibility criteria, terms, and conditions than regular personal loans. Lenders, for example, may impose age restrictions or require proof of pension income to qualify for a loan. Before applying for a personal loan, pensioners should carefully review the eligibility criteria as well as the terms and conditions. They should also compare various lenders and loan options to determine which one best meets their needs and financial situation.",
    },
    {
        question: "Can I use a personal loan for marriage expenses?",
        answer: 
  "Personal loans for pensioners may have different eligibility criteria, terms, and conditions than regular personal loans. Lenders, for example, may impose age restrictions or require proof of pension income to qualify for a loan. Before applying for a personal loan, pensioners should carefully review the eligibility criteria as well as the terms and conditions. They should also compare various lenders and loan options to determine which one best meets their needs and financial situation.",
      },

      {
        question: "Can I apply for a personal loan without a salary slip?",
        answer: 
  "Yes, it is possible to apply for a personal loan without a salary slip, but you will need to provide other financial documents to validate your income. Some lenders may accept an income certificate from your employer, bank account statements showing regular deposits, Income Tax Returns (ITR) or Form 16 as proof of your income. However, it's important to note that the lender may have specific requirements regarding the type and amount of documentation needed to verify your income. Additionally, having alternative forms of income may affect your eligibility and the terms of the loan, such as the interest rate or loan amount.",
      },
      {
        question: "Can I take out personal loans from two different banks at the same time?",
        answer: 
  "Yes, you can obtain personal loans from two different banks at the same time, provided you meet the eligibility requirements for each loan and can afford the repayments for both. However, it's critical to keep an eye on your overall debt level and avoid taking on more debt than you can handle.",
      },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-[1260px] mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">FAQs</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border rounded-lg overflow-hidden">
            <button
              className="w-full p-4 text-left font-semibold bg-gray-200 hover:bg-gray-300 focus:outline-none flex items-center justify-between"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
              {activeIndex === index ? (
                <FaChevronUp className="text-gray-600" />
              ) : (
                <FaChevronDown className="text-gray-600" />
              )}
            </button>
            {activeIndex === index && (
              <div className="p-4 bg-gray-100">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DsaFaq;
