import React, { useState } from 'react';
import LoanFeature from './LoanFeature';
import HomeLoanBenifits from './HomeLoanBenifits';
  import HomeLoanEligibility from './HomeLoanEligibility';
import HomeLoanDocumentation from './HomeLoanDocumentation';
import HomeLoanCharges from './HomeLoanCharges';
import HomeLoanReviews from './HomeLoanReviews';
import HomeLoanFaq from './HomeLoanFaq';
import HomeServices from './HomeServices';
const HomeLoanNavbar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  const tabs = [
    ' OVERVIEW',
    "FEATURES",
    "ELIGIBILITY",
    "DOCUMENTS",
    "EMI CALCULATOR",
    "FEES AND CHARGES",
    "REVIEWS",
    "FAQ`s"
  ];

  return (
    <>
      <div className="App">
        <div className="flex space-x-4 border-b-2 font-semibold">
          {tabs.map(tab => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <LoanFeature/>
      <HomeLoanBenifits/>
      <HomeLoanEligibility/>
      <HomeLoanDocumentation/>
      <HomeLoanCharges/>
      <HomeLoanReviews/>
      <HomeLoanFaq/>
      <HomeServices/>
    </>
  );
};

export default HomeLoanNavbar;
