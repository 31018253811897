import React from 'react';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import quotes from '../../creaditcardComponents/cardTypeImages/quotes.png';

const BussinessLoanReview = () => {
    const reviews = [
        {
            name: "Shreya Mehta",
            rating: 4,
            review: "I was going through a low in my business and was unable to get financial support from lenders. Under these circumstances, Sahil from RULOANS suggested me to apply for a top-up home loan and use that money to fund my business. Thanks to the top-up home loan facility that helped my business to survive even in the odds.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Chirag Joshi",
            rating: 4,
            review: "First, I used RULOANS emi calculator to check the approximate EMI for a home loan. I found the website very good and then I applied for a home loan with KOTAK bank from here. My loan got approved in 10 days only which is less for a home loan when compared to other home loan providers.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Chirag Joshi",
            rating: 4,
            review: "I got home loan offer from HDFC Limited through Ruloans. Earlier I was choosing some other bank but Ruloans facility of comparison helped me with a better choice.The amount was sufficient with tenure period of 15 years. The rate of interest is moderate. I took this loan to buy an apartment. EMI need to pay 36K. Pre closure is applicable but there is locking period of 6 months. Their service is good.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Chirag Joshi",
            rating: 4,
            review: "First, I used RULOANS emi calculator to check the approximate EMI for a home loan. I found the website very good and then I applied for a home loan with KOTAK bank from here. My loan got approved in 10 days only which is less for a home loan when compared to other home loan providers.",
            images: boy,
            quote: quotes,
        },
    ];

    return (
        <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col bg-white">
            <h2 className="text-3xl font-bold mb-8">Personal Loan Reviews</h2>
            <div className='grid lg:grid-cols-2 gap-8'>
                {reviews.map((review, index) => (
                    <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
                        <div className='flex items-start gap-4 mb-4'>
                            <img src={review.quote} alt="Quote Icon" className="w-10 " />
                            <p className='text-yellow-400 text-3xl ml-6'>
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </p>
                        </div>



                        <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
                            <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
                        </div>



                        <div className='flex items-center gap-4 mt-4'>
                            <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
                            <p className='font-semibold text-xl flex items-center'>
                                {review.name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BussinessLoanReview;
