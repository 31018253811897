import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbaar/Navbar'
import Footer from '../components/Footer/Footer'
const LayOut = () => {
  return (
   <div>
     <Navbar/>
      <Outlet/>
      <Footer/>
   </div>
  )
}

export default LayOut