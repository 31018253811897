import React from 'react';

function HomeLoanDocumentation() {
  return (
    <div className=" max-w-[1260px] mx-auto p-4">
      <h1 className="text-3xl font-semibold   mb-4">Documentation for Home Loan</h1>
      <div className="flex flex-col gap-4">
        <div className="bg-white p-4 rounded-md ">
          <h2 className="text-xl font-semibold mb-2">1. Income Proof Documents</h2>
          <ul className='flex flex-col gap-2 list-disc ml-6'>
            <li className="mb-1">Form 16</li>
            <li className="mb-1">Employee Identity Card</li>
            <li className="mb-1">3 Months Salary Slip</li>
            <li className="mb-1">6 Month Bank Account Statement</li>
            <li>Duration of Employment Evidence</li>
          </ul>
        </div>
        <div className="bg-white p-4 rounded-md">
          <h2 className="text-xl font-semibold mb-2">2. Self-Employed Individuals</h2>
          <ul className='flex flex-col gap-2 list-disc ml-6'>
            <li className="mb-1">PAN</li>
            <li className="mb-1">Trade License</li>
            <li className="mb-1">Partnership Deed</li>
            <li className="mb-1">Article of Association</li>
            <li className="mb-1">Memorandum of Association</li>
            <li className="mb-1">Import Export Code</li>
            <li className="mb-1">SEBI Registration Certificate</li>
            <li className="mb-1">ROC Registration Certificate</li>
            <li className="mb-1">Financial Statement Audited by CA</li>
            <li className="mb-1">Profit & Loss Account Statement</li>
            <li className="mb-1">Balance Sheet</li>
            <li className="mb-1">6 Months Bank Account Statement</li>
            <li className="mb-1">Professional Practice License for Doctors, Consultants, etc</li>
            <li className="mb-1">Registration Certificate of Establishment for Shops, Factories, and Other Establishments</li>
            <li>Business Address Proof</li>
          </ul>
        </div>
        <div className="bg-white p-4 rounded-md ">
          <h2 className="text-xl font-semibold mb-2">3. Documents Required from all Non-Resident Indians (NRIs) Applicants</h2>
          <ul className='flex flex-col gap-2 list-disc ml-6'>
            <li className="mb-1">Income Proof Documents for NRI</li>
            <li className="mb-1">Property Papers</li>
            <li className="mb-1">Property Documents</li>
            <li className="mb-1">Sale Deed</li>
            <li className="mb-1">Stamped Agreement of Sale</li>
            <li className="mb-1">Allotment Letter</li>
            <li className="mb-1">No Objection Certificate or NOC</li>
            <li className="mb-1">Housing Society</li>
            <li className="mb-1">Builder</li>
            <li className="mb-1">Possession Certificate</li>
            <li className="mb-1">Land Tax Receipt</li>
            <li className="mb-1">Construction Cost Estimate</li>
            <li className="mb-1">Bank Account Statement of Payment Made to Seller or Builder</li>
            <li className="mb-1">Payment Receipt of Payment Made to Seller or Builder</li>
            <li className="mb-1">Incase of resale property share certificate is required</li>
            <li>Occupancy Certificate</li>
          </ul>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-2">EMI Calculator for Home Loan</h2>
        <p className='text-lg'>
          The monthly part-payment that you make to repay your Home Loan is known as an Equated Monthly Instalment (EMI). This monthly payment includes repayment of the
          principal amount of your loan divided over the period of the loan, with the agreed interest amount on the outstanding amount of your home loan.
          Ruloans online Home Loan EMI Calculator is essential to make an up-to-date decision to execute your Home Loan Plan at optimum levels. This EMI calculator online aids
          you to perform complex computing functions to calculate your Home Loan Equated Monthly Instalment accurately in an instant.
        </p>
      </div>
    </div>
  );
}

export default HomeLoanDocumentation;