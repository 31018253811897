
import React from 'react';
import { Link } from 'react-router-dom';
import DetailsCreditCards from './DetailsCreditCards';
import CardType from './CardType';
import CreditCardFeatures from './CreditCardFeatures';
import CreditCardFeatureDetails from './CreditCardFeatureDetails';
import CreditCardEligibility from './CreditCardElegibility';
import CreditCardEligibilityRead from './CreditCardElegibiltyRead';
import DocumentsRequired from './DocumentsRequired';
import CreditCardReviews from './CreditCardReviews ';

function CreditCardNavbar() {
  return (
         <>
             <nav className='max-w-[1260px] mx-auto'>
        <div>
            <ul className='flex gap-8 uppercase items-center font-semibold'>
                <li className='text-red-600 font-semibold'>overview</li>
                <span className='h-6 w-0.5 bg-gray-400'></span>
                <li className='text-gray-400'>Eligibility</li>
                <span className='h-6 w-0.5 bg-gray-400'></span>
                <li className='text-gray-400'>documents</li>
                <span className='h-6 w-0.5 bg-gray-400'></span>
                <li className='text-gray-400'>reviews</li>
                <span className='h-6 w-0.5 bg-gray-400'></span>
                <li className='text-gray-400'>faq</li>
            </ul>
        </div>
    </nav>

    <DetailsCreditCards/>
    <CardType/>
    <CreditCardFeatures/>
    <CreditCardFeatureDetails/>
    <CreditCardEligibility/>
    <CreditCardEligibilityRead/>
    <DocumentsRequired/>
    <CreditCardReviews/>
         </>
  );
}

export default CreditCardNavbar;