import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons from react-icons

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is BluBucks?",
      answer: "BluBucks is an innovative one-stop-shop for all your financial needs, including Home Loans, Personal Loans, Credit Cards, Business Loans, Insurance, Balance Transfers, and more. Experience: Our team's deep industry knowledge and extensive experience enable us to offer insightful financial solutions tailored to your unique needs. Nationwide Presence: With a PAN India presence across 2000+ cities, we are dedicated to reaching every corner of the country to make financial assistance accessible to all. Cutting-Edge Technology: Our internally developed Loan Calculator algorithm and algorithmic engines empower you with accurate, up-to-date, and forward-looking insights, aiding you in making informed decisions. Customer-Centric Approach: You are at the heart of everything we do. Our customer-centric ethos drives us to prioritize your satisfaction and financial success."
    },
    {
      question: "Why Choose BluBucks?",
      answer: "BluBucks offers a comprehensive suite of financial products and services designed to meet your specific needs. Our expert team, advanced technology, and extensive network ensure that you receive the best solutions tailored to your requirements."
    },
    {
      question: "Are there any charges for applying for a loan?",
      answer: "There might be some charges while applying for any type of loan. These charges might differ from lender to lender."
    }
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div >
       <div className="max-w-[1260px] mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">FAQs</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border rounded-lg overflow-hidden">
            <button
              className="w-full p-4 text-left font-semibold bg-gray-200 hover:bg-gray-300 focus:outline-none flex items-center justify-between"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
              {activeIndex === index ? (
                <FaChevronUp className="text-gray-600" />
              ) : (
                <FaChevronDown className="text-gray-600" />
              )}
            </button>
            {activeIndex === index && (
              <div className="p-4 bg-gray-100">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default FAQ;
