import React from 'react';

const CreditCardEligibilityRead = () => {
  return (
    <div className="max-w-screen-xl mx-auto rounded-lg p-6">
      <h2 className="text-4xl font-semibold mb-4">Credit Card Eligibility</h2>
      <p className="mb-6 text-lg">
        Credit card eligibility refers to the criteria that an individual must meet in order to qualify for a credit card issued by a financial institution. These criteria can vary between different credit card issuers, but they generally include factors such as:
      </p>
      <ul className="list-disc mb-6 ml-8 flex flex-col gap-2 text-lg">
        <li> <span className='font-semibold'>Age</span>: Most credit card issuers require applicants to be a certain age, typically 18 or older. Some may have a higher age requirement.</li>
        <li> <span className='font-semibold'>Income</span>: Credit card issuers often have a minimum income requirement to ensure that applicants have the means to repay the credit card debt.</li>
        <li><span className='font-semibold'>Credit Score</span>: A good credit score is important for credit card eligibility. A higher credit score indicates a history of responsible credit behavior and makes you more likely to be approved for a credit card with favorable terms.</li>
        <li><span className='font-semibold'>Residential Status</span>: Depending on the issuer, you might need to provide proof of your current address or residency.</li>
      </ul>
      <p className="text-gray-700 mb-6 text-lg">
        It's important to note that meeting these eligibility criteria does not guarantee approval for a credit card. The final decision rests with the credit card issuer based on their assessment of your application and creditworthiness. Additionally, different credit cards may have different eligibility criteria and features, so it's a good idea to carefully review the terms and conditions of the credit card you're interested in before applying.
      </p>
    </div>
  );
};

export default CreditCardEligibilityRead;
