import React from 'react';

const CreditCardFeatureDetails = () => {
  return (
    <div className="max-w-[1260px] mx-auto px-4 py-8">
      <h1 className="text-4xl mt-8 font-semibold mb-8">
        Features & Benefits of Credit Cards
      </h1>
      <p className="text-lg mb-4 ml-2">
        The credit card segment continues to grow universally as innovative
        contenders enter the space with fresh and advanced features. Get a look
        at the basic features.
      </p>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2 inline-block">Credit Limit:</h2>
        <p className="text-lg inline ml-2">
           Credit limit refers to the maximum amount you can use through your
          credit card at a given point of time. You are liable to be charged a
          fee on exceeding the given credit limit. Credit Limits may change from
          month to month based on purchase and payment habits.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2 inline-block">Balance:</h2>
        <p className="text-lg inline ml-2">
          The balance on your credit card represents the total amount you owe.
          This includes purchases, finance charges, and fees. You may check your
          balance online, or by calling customer service.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2 inline-block">APR:</h2>
        <p className="text-lg inline ml-2">
          APR refers to the term Annual Percentage Rate, an interest rate
          applicable to the balance you carry forward past the grace period
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2 inline-block">Grace Period:</h2>
        <p className="text-lg inline ml-2">
          Grace Period refers to the period of time you are allowed to repay
          your balance in full before being charged a fee. Typically, balance
          transfers and cash advances do not have grace periods.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2 inline-block">Credit Card Fees:</h2>
        <p className="text-lg inline ml-2">
          The most common fees include annual fees, finance charges, late fees,
          and over-the-limit fees. You may avoid certain fees based on how you
          use your card; for example by making credit card repayments on time.
        </p>
      </div>
    </div>
  );
};

export default CreditCardFeatureDetails;
