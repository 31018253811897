import React, { useState } from 'react';
import BussinessLoanInfo from './BussinessLoanInfo';
import BussinesLoanEligibility from './BussinesLoanEligibility'
import BussinessLoan from './BussinessLoan';
import BussinessLoanCharges from './BussinessLoanCharges';
import BussinessLoanReview from './BussinessLoanReview';
import BussinesLoanfaq from './BussinesLoanfaq';
const BussinessLoanNavbar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  const tabs = [
    'OVERVIEW',
    'FEATURES',
    'ELIGIBILITY',
    'DOCUMENTS',
    'EMI CALCULATOR',
    'FEES AND CHARGES',
    'REVIEWS',
   ' FAQ`s'
  ];

  return (
    <>
      <div className="App">
        <div className="flex space-x-4 border-b-2 font-semibold">
          {tabs.map(tab => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
       <BussinessLoanInfo/>
       <BussinesLoanEligibility/>
       <BussinessLoan/>
       <BussinessLoanCharges/>
       <BussinessLoanReview/>
       <BussinesLoanfaq/>

    </>
  );
};

export default BussinessLoanNavbar;
