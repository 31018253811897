import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1: Social Media Links */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Follow Us</div>
            <div className="flex space-x-4">
              <a href="#" className="text-2xl hover:text-green-500">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="#" className="text-2xl hover:text-red-500">
                <i className="fas fa-times"></i>
              </a>
              <a href="#" className="text-2xl hover:text-blue-600">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-2xl hover:text-blue-800">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#" className="text-2xl hover:text-pink-600">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-2xl hover:text-gray-400">
                <i className="fas fa-play"></i>
              </a>
              <a href="#" className="text-2xl hover:text-gray-400">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>

          {/* Column 2: Most Searched Links */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Most Searched Links</div>
            <ul className="space-y-2 text-base">
              <li><a href="#" className="hover:text-gray-400">DSA Partner</a></li>
              <li><a href="#" className="hover:text-gray-400">Direct Selling Agent</a></li>
              <li><a href="#" className="hover:text-gray-400">Credit Card</a></li>
              <li><a href="#" className="hover:text-gray-400">Car Refinance</a></li>
              <li><a href="#" className="hover:text-gray-400">DSA Loan</a></li>
              <li><a href="#" className="hover:text-gray-400">Free Credit Report</a></li>
              <li><a href="#" className="hover:text-gray-400">DSA Loan Agent</a></li>
              <li><a href="#" className="hover:text-gray-400">Personal EMI Calculator</a></li>
              <li><a href="#" className="hover:text-gray-400">Become Loan DSA</a></li>
            </ul>
          </div>

          {/* Column 3: Company Info & Products */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Company & Products</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-base">
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-400">About Us</a></li>
                <li><a href="#" className="hover:text-gray-400">News</a></li>
                <li><a href="#" className="hover:text-gray-400">Grow With Us</a></li>
                <li><a href="#" className="hover:text-gray-400">Contact Us</a></li>
                <li><a href="#" className="hover:text-gray-400">Privacy Policy</a></li>
              </ul>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-gray-400">Insurance</a></li>
                <li><a href="#" className="hover:text-gray-400">Home Loan</a></li>
                <li><a href="#" className="hover:text-gray-400">Personal Loan</a></li>
                <li><a href="#" className="hover:text-gray-400">Business Loan</a></li>
                <li><a href="#" className="hover:text-gray-400">Car Loan</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="bg-gray-800 text-center py-4 mt-8">
        <div className="container mx-auto text-sm">
          <p>© 2024 BluBucks — All Rights Reserved</p>
          <p>Toll-free Number </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
