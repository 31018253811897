import React from 'react';
import TopBanner from './TopBanner';

function Note() {
  return (
    <>
    <div className="bg-blue-100 p-4 rounded-md max-w-[1260px] mx-auto shadow-md text-lg mt-4">
      <div className="flex items-center mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-yellow-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 5.882V19.24a1.76 1.76 0 01-1.76 1.76H5.5a1.76 1.76 0 01-1.76-1.76V5.5a1.76 1.76 0 011.76-1.76h.75l3.372 3.372a1.76 1.76 0 011.245.515l.515.515a1.76 1.76 0 01.515 1.245l3.372 3.372h.75a1.76 1.76 0 011.76 1.76v13.76a1.76 1.76 0 01-1.76 1.76H5.5a1.76 1.76 0 01-1.76-1.76V5.5a1.76 1.76 0 011.76-1.76h5.242z"
          />
        </svg>
        <span className="font-bold ml-2">Note:</span>
      </div>
      <p className="text-gray-700">
        We do not charge for our services to customers. If any Ruloans personnel
        take any charges other than bank fees, please immediately contact our
        toll-free number or write to us.
      </p>
    </div> 
    <TopBanner/>
    </>
  );
}

export default Note;
