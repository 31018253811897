import React from 'react';
import { FaRegCircle } from 'react-icons/fa';

function TimelineSection() {
  const timelineData = [
    { year: '2000', description: 'Commenced with Standard Chartered Bank Credit Cards' },
    { year: '2004', description: 'Establish personal loan and telecom business' },
    { year: '2008', description: 'Progressed into a distribution partner for multi-banks & NBFCs' },
    { year: '2010', description: 'Initiated national expansion' },
    { year: '2014', description: 'Towns = 500+ Team = 1,200+ Business = Rs 300+ Crs.' },
    { year: '2019', description: 'Towns = 1,200+ Team = 7,000 Business = Rs 1,000+ Crs.' },
    { year: '2021', description: 'Towns = 2,000+ Team = 10,000 Business = Rs 1,500+ Crs.' },
    { year: '2023', description: 'Launched India\'s 1st B2B channel partner app, RUCConnect App...' }
  ];

  return (
     <div>
        <div className="max-w-7xl mx-auto py-20 px-6 ">
      <h2 className="text-center text-5xl font-extrabold mb-16 text-gray-800">
        Our Journey Over the Years
      </h2>
      <div className="relative mb-12 text-black">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {timelineData.map((item, index) => (
            <div key={index} className="relative flex flex-col items-center">
              <div className="flex items-center justify-center w-14 h-14 bg-red-600 text-black rounded-full z-10 mb-4">
                <FaRegCircle className="w-6 h-6" />
              </div>
              <h3 className="text-2xl font-bold  ">{item.year}</h3>
              <p className="mt-2 text-lg   text-center leading-relaxed">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center">
        <button className="bg-gradient-to-r from-red-500 to-red-700 text-black px-10 py-4 rounded-full text-xl font-semibold shadow-lg hover:from-red-600 hover:to-red-800 transition duration-300">
          Learn More
        </button>
      </div>
    </div>
     </div>
  );
}

export default TimelineSection;
