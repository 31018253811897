import { useState } from 'react';

const BussinessLoanCharges = () => {
  const [chargesData, setChargesData] = useState([
    { particular: 'Loan Processing Fees', charge: '₹4999 to 5% of Loan Amount' },
    { particular: 'Loan Cancellation', charge: 'Usually ₹3000 to 5% of Loan Amount' },
    { particular: 'Stamp Duty Charges', charge: '₹150 to ₹300' },
    { particular: 'Legal Fees', charge: 'As per actuals' },
    { particular: 'Penal Charges', charge: 'Usually 2% per month' },
    { particular: 'EMI / Cheque Bonus', charge: 'Approx ₹499 to ₹599' },
  ]);

  return (
    <>
      <div className='flex flex-col gap-4 max-w-[1260px] mx-auto mt-4'>
        <h1 className='text-2xl font-bold'>
        Fees and Charges for Business Loan
        </h1>
        <p className='text-xl'>The fees and charges of personal loans usually vary from lender to lender and from case to case. The aforementioned table will give you a fair idea of the fees and charges related to personal loans:</p>
      </div>
       <div className="bg-white p-8 rounded-lg shadow-lg max-w-[1260px] mx-auto mt-10">
      <h2 className="text-2xl font-bold text-red-500 mb-6">Charges</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full text-left text-gray-700">
          <thead className="bg-blue-200">
            <tr>
              <th className="px-6 py-3 text-sm font-semibold text-gray-900 uppercase">Particulars</th>
              <th className="px-6 py-3 text-sm font-semibold text-gray-900 uppercase">Charges</th>
            </tr>
          </thead>
          <tbody>
            {chargesData.map((charge, index) => (
              <tr key={index} className="border-b border-gray-200 hover:bg-blue-50">
                <td className="px-6 py-4 text-lg font-semibold">{charge.particular}</td>
                <td className="px-6 py-4 text-lg">{charge.charge}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="mt-6 text-gray-600 text-lg">
        Additional fees and charges such as documentation fees, verification charges, duplicate statement fees, NOC certificate fees, and swap charges may apply.
      </p>
    </div>
    </>
  );
};

export default BussinessLoanCharges;
