import React from 'react'
import LoansRoute from './routes/LoansRoute'
import CrouselHero from './components/CarouselHero/CrouselHero'
import ContactForm from './components/InsuranceSec/ContactForm/ContactForm'
import InsuranceFeatures from './components/InsuranceSec/InsuranceFeatures/InsuranceFeatures'
import GeneralInsurance from './components/InsuranceSec/GeneralInsurance/GeneralInsurance'
import BestServices from './components/InsuranceSec/BestServices/BestServices'
import DistributedContact from './components/become partner/DistributedLoanContact/DistributedContact'
import DSAwithRuloans from './components/become partner/DSAwithRuloans/DSAwithRuloans'
import DSAwithRuloans1 from './components/become partner/DSAwithRuloans1/DSAwithRuloans1'

function App() {
  return (
    <>  
     <LoansRoute/>
    </>

  )
}

export default App