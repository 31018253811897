import React from 'react';
import aboutTopImg from './AboutImages/aboutTopImg.png';

const TopBanner = () => {
  return (
    <div className="mt-4 max-w-[1260px] mx-auto flex text-lg  flex-col md:flex-row justify-between gap-4 md:gap-6 md:p-10 rounded-lg shadow-lg">
      <div className="flex-1 md:w-1/2 flex justify-center items-center">
        <img 
          src={aboutTopImg} 
          alt="About us" 
          className="w-full max-w-md rounded-lg object-cover" 
        />
      </div>
      <div className="flex-1 md:w-1/2 flex flex-col justify-center gap-4 p-4">
        <h2 className="text-2xl font-semibold text-red-400">Who Are We?</h2>
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
          We Sanction Your Dreams and Give You a Happy Disbursal!
        </h1>
        <p className="text-gray-700 mb-4">
          <a href="#" className="text-blue-400 underline hover:text-blue-600">
            Ruloans
          </a> is an innovative one-stop-shop for all your financial needs, including 
          <a href="#home-loans" className="text-blue-400 underline hover:text-blue-600 mx-1">Home Loans</a>, 
          <a href="#personal-loans" className="text-blue-400 underline hover:text-blue-600 mx-1">Personal Loans</a>, 
          <a href="#credit-cards" className="text-blue-400 underline hover:text-blue-600 mx-1">Credit Cards</a>, 
          <a href="#business-loans" className="text-blue-400 underline hover:text-blue-600 mx-1">Business Loans</a>, 
          Balance Transfers, and more.
        </p>
        <p className="text-gray-700 mb-4">
          With a PAN India presence across 2000+ cities, Ruloans is one of the leading credit lending establishments founded by professionals and financial experts who, with an experience of 25+ years, have created a rich profile of satisfying the needs of more than 10 lakhs+ clients. Our internally developed highly proficient Loan Calculator algorithm helps us serve you the best options and deals for the loan/financial product of your choice. Our algorithmic engines help you by using strong factual data made from relative latest changes in the market and a calculated understanding of the future trends.
        </p>
        <p className="text-gray-700 text-xl">
          Connect with us to help you make the right and smart financial decisions.
        </p>
      </div>
    </div>
  );
};

export default TopBanner;
