import { useState } from 'react';

function CarFeature() {
  
  return (
    <div className="max-w-[1260px] mx-auto mt-4">
      <h2 className="text-4xl font-semibold  mb-4">
      Features and Benefits of our Car Loan
      </h2>
      <div className="flex justify-center">
         
      </div>
      
        <ul className="pl-6 mt-4 flex flex-col gap-2 text-lg">
          <li>
            <span className="font-bold">Secured Loan:</span> The loan is
            secured against the value of your property. The property acts as
            collateral, reducing the risk for the lender. This generally leads to
            lower interest rates compared to unsecured loans.
          </li>
          <li>
            <span className="font-bold">Loan Amount:</span> The loan amount is
            determined based on the value of the property you pledge. Generally,
            you can get a higher loan amount compared to personal loans or other
            unsecured loans.
          </li>
          <li>
            <span className="font-bold">Flexible Tenure:</span> The tenure
            (repayment period) for a Loan Against Property is usually longer
            compared to other types of loans, often ranging from 5 to 20 years.
            This allows for lower monthly installments.
          </li>
          <li>
            <span className="font-bold">Multipurpose:</span> The loan amount can
            be used for a wide range of purposes, giving you the flexibility to
            address various financial needs without any restrictions.
          </li>
          <li>
            <span className="font-bold">Improves Credit Scores:</span>
            Successfully repaying a Loan Against Property can positively impact
            your credit score, as it demonstrates responsible borrowing behavior.
          </li>
        </ul>
      
    </div>
  );
}

export default CarFeature;