import React from 'react';

const PersonalLoanBenifit = () => {
  return (
    <div className="max-w-[1260px] mx-auto container   px-4">
      <h2 className="text-3xl font-bold   mb-8">
        Features and Benefits of our Personal Loan
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        A personal loan is an unsecured loan that is not backed by collateral or
        security. This makes it a flexible financing option, as there are no
        limitations on its use.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        The following are the ways a personal loan can be useful:
      </p>
      <ul className="list-disc ml-6 text-lg">
        <li className="mb-3">
          <span className="font-bold">Debt consolidation:</span> Combining
          multiple debts into a single loan with a lower interest rate to save
          money on interest payments and make it easier to manage debts.
        </li>
        <li className="mb-3">
          <span className="font-bold">Home renovation:</span> Using a personal
          loan to fund home renovation projects can increase the value of your
          home and improve your living conditions.
        </li>
        <li className="mb-3">
          <span className="font-bold">Medical expenses:</span> If you have
          unexpected medical expenses, such as emergency surgery or
          hospitalization, a personal loan can help cover the costs.
        </li>
        <li className="mb-3">
          <span className="font-bold">Emergency expenses:</span> A personal
          loan can help cover unexpected expenses like medical emergencies or
          car repairs to avoid financial hardship.
        </li>
      </ul>
    </div>
  );
};

export default PersonalLoanBenifit;